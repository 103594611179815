<template>
  <div class="mx-auto" :class="isMobile ? 'mb-4' : 'mb-5'">
    <div class="fs-20 font-weight-bold mt-20 detail-title">
        {{ info.rego ? `[${info.rego}]` : '' }}
        {{ info.year }} {{ info.make.toUpperCase() }}
        {{ info.model.toUpperCase() }}
        {{ info.badge ? info.badge.toUpperCase() : '' }}
        {{ info.edition ? info.edition.toUpperCase() : '' }}
    </div>
    <div class="customer-detail mx-auto">
      <div class="customer-detail-content text-center mx-auto">
        <p class="title fs-18 mx-auto">
          <b>Almost there, just a few more details!</b>
        </p>
        <div class="content-detail mx-auto">
          <div class="position-relative">
            <float-label label="Name">
              <b-input
                v-model.trim="customerDetail.customerName"
                ref="nameInput"
                placeholder="Enter your name"
                autocomplete="off"
                required
                :class="[
                  {
                    error: $v.customerDetail.customerName.$error,
                  },
                  'shadow-none mg-top',
                ]"
                @keyup.enter="setNextInput('email')"
              />
              <p
                v-if="
                  $v.customerDetail.customerName.$dirty &&
                  !$v.customerDetail.customerName.required
                "
                class="msg-error mb-0"
              >
                <i>Please enter your name</i>
              </p>
              <p
                v-else-if="
                  $v.customerDetail.customerName.$dirty &&
                  !$v.customerDetail.customerName.valid
                "
                class="msg-error mb-0"
              >
                <i
                  >Please enter only alphabet characters, numerics and space!</i
                >
              </p>
              <p
                v-else-if="
                  $v.customerDetail.customerName.$dirty &&
                  !$v.customerDetail.customerName.maxLength
                "
                class="msg-error mb-0"
              >
                <i>Please enter 1 to 110 characters!</i>
              </p>
            </float-label>
          </div>
          <div class="position-relative">
            <float-label label="Email">
              <b-input
                v-model.trim="customerDetail.customerEmail"
                ref="email"
                :placeholder="emailGhostText ? emailGhostText : 'Enter your email'"
                autocomplete="off"
                required
                :class="[
                  {
                    error: $v.customerDetail.customerEmail.$error || !isCorrectEmailDomain,
                    'mt-5':
                      $v.customerDetail.customerName.$dirty &&
                      !$v.customerDetail.customerName.valid,
                  },
                  'shadow-none mt-30',
                ]"
                @keyup.enter="setNextInput('mobile')"
              />
              <p
                v-if="
                  $v.customerDetail.customerEmail.$dirty &&
                  !$v.customerDetail.customerEmail.required
                "
                class="msg-error mb-0"
              >
                <i>Please enter your email</i>
              </p>
              <p
                v-else-if="
                  $v.customerDetail.customerEmail.$dirty &&
                  !$v.customerDetail.customerEmail.email
                "
                class="msg-error mb-0"
              >
                <i>Please enter a valid email address!</i>
              </p>
              <p
                v-else-if="
                  $v.customerDetail.customerEmail.$dirty &&
                  !$v.customerDetail.customerEmail.maxLength
                "
                class="msg-error mb-0"
              >
                <i>Please enter 1 to 256 characters!</i>
              </p>
              <p
                v-else-if="!isCorrectEmailDomain"
                class="msg-error mb-0"
              >
                <i>Invalid email address entered!</i>
              </p>
            </float-label>
          </div>
          <div 
            class="position-relative"
            :class="(isPhoneNumberValid || !isNotShowErrMsg) && customerDetail.customerMobile.length !== 0 ? 'content-detail-mobile' : ''"
          >
            <float-label label="Mobile">
              <b-input
                type="tel"
                v-model.trim="customerDetail.customerMobile"
                ref="mobile"
                placeholder="Enter your phone"
                autocomplete="off"
                required
                :class="[
                  {
                    error: $v.customerDetail.customerMobile.$error,
                  },
                  'shadow-none mt-30',
                ]"
                @keydown.space.prevent
                @focusout="handleFocusOut"
              />
              <p
                v-if="
                  $v.customerDetail.customerMobile.$dirty &&
                  !$v.customerDetail.customerMobile.required
                "
                class="msg-error mb-0"
              >
                <i>Please enter your phone</i>
              </p>
              <p
                v-else-if="isPhoneNumberValid || !isNotShowErrMsg"
                class="msg-error mb-0"
              >
                <i>Please enter a valid mobile number starting with '+642' or '02'</i>
              </p>
              <p
                v-else-if="isInPhoneNumberRule || phoneNumberRuleCheck"
                class="msg-error mb-0"
              >
                <i>Invalid mobile number</i>
              </p>
            </float-label>
          </div>
          <google-place-autocomplete
            label="Car pick-up location"
            v-model="customerDetail.customerPostalCode"
            :error="$v.customerDetail.customerPostalCode.$error"
            name="location"
            placeholder="Start typing your full address"
            :helperText="postalCodeHelperText"
            class="mt-30 pickup-location"
            @input="handleLocationInput"
            :isMobile="isMobile"
          />
          <custom-select
            label="When are you looking to sell?"
            v-model="customerDetail.sell"
            :options="sells"
            :error="$v.customerDetail.sell.$error"
            helperText="Please select one option"
            class="mt-10"
          />
          <div class="disclaimer mt-15">
            <span class="disclaimer-text">
              Your privacy is important to us. We will only use your personal information to contact you about your enquiry.
            </span>
          </div>
          <div class="seller-customer">
            <p><b>I agree to:</b></p>
            <b-form-checkbox v-model="customerDetail.sellerTermsAndFees" class="seller-checkbox font-weight-thin mt-3">
              <span>
                I agree to and acknowledge AutoFlip's seller 
                <a :href="policyDocumentsLinks.sellerAgreement" target="_blank">agreement</a> and 
                <a :href="policyDocumentsLinks.successFee" target="_blank">success fee</a> - NO DEAL, NO FEE
              </span>
            </b-form-checkbox>
            <b-form-checkbox v-model="customerDetail.marketingComm" class="seller-checkbox font-weight-thin mt-3">
              <span>
                Receive occasional promotional communications from AutoFlip.
                We will not spam you and you can opt out at any time.
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <btn-group
        @onOk="submitForm"
        okText="submit"
        @onCancel="goBack"
        :okBtnDisable="activeBtnAction"
        :isLoadingApi="isLoadingApi"
        :query=this.$route.query
      />
    </div>
    <p class="customer-detail-txt-note font-weight-thin mt-2 mb-0 mx-auto">
      <!-- Vehicle valuations are provided by gathering data from several sources.
      The valuations displayed are estimates only and are based on the vehicle
      information provided by the vehicle owner at the time of submitting this
      form, including vehicle specification, kilometer, and condition. The
      actual price of the vehicle may vary, AutoFlip assumes no responsibility
      for any errors or omissions -->
    </p>
    <div :style="isMobile ? 'height: 100px;' : 'height: 30px;'"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
import mixin from "@/mixins/common";
import { SET_INFO } from "@/store/info/actions";
import customerDetailService from "@/services/customer-detail";
import { LP_TYPE, locid } from "@/config";
import { eraseCookie } from "@/helper/cookie";
import { removeFormatNumberWithComma } from "../../helper/utils";
import { LocationValMap, logError } from '../../helper/utils';
import debounce from "lodash/debounce";

const regexName = helpers.regex("name", /^[a-zA-Z0-9 ]+$/);
// const regexPhone = helpers.regex(
//   "number",
//   /^(\+614|04)\d{8,}$/
// );
const regexPhoneRule = helpers.regex(
  "number",
  /^(02|\+642)/
);

export default {
  name: "CustomerDetail",
  mixins: [mixin],
  data() {
    return {
      customerDetail: { ...this.$store.getters.curVehicleInfo },
      isLoadingApi: false,
      isMobile: true,
      from: 0,
      to: 0,
      discreteValue: 0,
      suburbAndPostcodes: [],
      isPhoneNumberValid: false,
      isInPhoneNumberRule: false,
      isNotShowErrMsg: false,
      isValidLocation: false,
      isCorrectEmailDomain: true,
    };
  },
  methods: {
    getPriceRange() {
    customerDetailService
      .getPriceRange({
        make: this.info.make,
        model: this.info.model,
        year: this.info.year,
        badge: this.info.badge !== "Not sure" ? this.info.badge : "",
        edition: this.info.edition !== "Not sure" ? this.info.edition : "",
        bodyType: this.info.bodyType,
        drive: this.info.drive,
        transmission: this.info.transmission,
        valuationType: "Range",
        km: removeFormatNumberWithComma(this.info.kms),
        exteriorCondition: this.info.exterior,
        vin: this.info.vin,
        modelYear: this.info.model_year,
        rbcs: encodeURIComponent(JSON.stringify(this.info.rbcs)),
        locid,
      })
      .then(({ data }) => {
        this.from = data && (data.from || 0);
        this.to = data && (data.to || 0);
        this.discreteValue = data && (data.discreteValue || 0);
      });
    },
    async submitForm() {
      this.$v.customerDetail.$touch();
      if (!this.phoneNumberValidCheck() || 
        (!this.$v.customerDetail.$invalid && !this.isLoadingApi && 
        (this.customerDetail.customerMobile === '0' || this.customerDetail.customerMobile === '02' ||
        this.customerDetail.customerMobile === '+' || this.customerDetail.customerMobile === '+6' ||
        this.customerDetail.customerMobile === '+64' || this.customerDetail.customerMobile === '+642'))) {
          this.isNotShowErrMsg = false;
      }
      if (!this.$v.customerDetail.$invalid && !this.isLoadingApi && this.activeBtnAction) {
        this.$store.dispatch(SET_INFO, {
          ...this.customerDetail,
          from: this.from,
          to: this.to,
          discreteValue: this.discreteValue,
        });
        this.isLoadingApi = true;
        const transKms = removeFormatNumberWithComma(this.info.kms);
        const ipAddress = await this.getClientIP();
        customerDetailService
          .carDetail({
            ...this.customerDetail,
            leadSource: LP_TYPE,
            queryString: this.queryString,
            km: transKms,
            kms: transKms,
            searched: this.searched,
            usedGuidedCamera: this.takePhotoWithGuidedCamera,
            locid,
            images: this.$store.getters.uploadImage.filter(image => !Object.hasOwnProperty.call(image, "err")),
            rbc: this.info.rbcs.length === 1 ? this.info.rbcs[0] : '',
            ipAddress,
          })
          .then((res) => {
            if (res.status === 201 && res.data) {
              eraseCookie("qs");
              this.isLoadingApi = false;
              this.$router.push({ name: "thanks-page" });
            }
          })
          .catch((err) => {
            this.isLoadingApi = false;
            if (err.response && err.response.status === 422) {
              const errors = err.response.data.errors;
              Object.keys(errors).forEach(field => {
                this.$toast.clear();
                this.$toast.error(`${err.response.data.message}: ${errors[field][0]}`);
              });
            } else if (err.response) {
              this.$toast.clear();
              this.$toast.error(err.response.data.message);
            } else {
              logError(`Submission error: ${err}`);
            }
          });
      }
    },
    goBack: function() {
      if (!this.isLoadingApi) {
        this.$store.dispatch(SET_INFO, {
            ...this.customerDetail,
          });
        this.$router.push({ name: "photo-upload", query: this.$route.query });
      }
    },
    setNextInput(val) {
      this.$refs[val].focus();
    },
    phoneNumberValidCheck() {
      if (this.customerDetail.customerMobile === '0' || this.customerDetail.customerMobile === '02' || 
        this.customerDetail.customerMobile === '+' || this.customerDetail.customerMobile === '+6' ||
        this.customerDetail.customerMobile === '+64' || this.customerDetail.customerMobile === '+642' ||
        (this.customerDetail.customerMobile.startsWith('02') && 
        (this.customerDetail.customerMobile.length >= 9 && this.customerDetail.customerMobile.length <= 11)) ||
        (this.customerDetail.customerMobile.startsWith('+642') && 
        (this.customerDetail.customerMobile.length >= 10 && this.customerDetail.customerMobile.length <= 12))
      ) {
        this.isNotShowErrMsg = true;
        return true;
      } else {
        this.isNotShowErrMsg = false;
        return false;
      }
    },
    handleFocusOut() {
      if (this.customerDetail.customerMobile.length > 0) {
        this.phoneNumberValidCheck();
        this.$v.customerDetail.customerMobile.$touch();
      } 
    },
    handleLocationInput: function (val) {
      if (val !== '') {
        this.isValidLocation = true;
      } else {
        this.isValidLocation = false;
      }
    },
    async getClientIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching client IP:', error);
      }
    },
    async checkEmailWithDns(email) {
      if (!email.includes("@") || this.$v.customerDetail.customerEmail.$invalid) return;
      const domain = email.split("@")[1];
      try {
        const response = await customerDetailService.resolveDomain(domain);
        this.isCorrectEmailDomain =
          response.status === 200 && response.data ? true : false;
      } catch (error) {
        console.error("Error resolving domain:", error);
        this.isCorrectEmailDomain = false;
      }
    },
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      info: (state) => state.info.curVehicleInfo,
      queryString: (state) => state.common.queryString,
      searched: (state) => state.regoSearch.searched,
      takePhotoWithGuidedCamera: (state) => state.photo.takePhotoWithGuidedCamera,
      policyDocumentsLinks: (state) => state.commonVariables.policyDocumentsLinks,
      emailGhostText: (state) => state.commonVariables.copyBlocks.emailGhostText,
    }),
    activeBtnAction: function () {
      return (
        !this.$v.customerDetail.customerName.$error &&
        !this.$v.customerDetail.customerEmail.$error &&
        !this.$v.customerDetail.customerMobile.$error &&
        !this.$v.customerDetail.sell.$error &&
        !this.$v.customerDetail.customerPostalCode.$error &&
        !!this.customerDetail.customerName &&
        !!this.customerDetail.customerEmail &&
        !!this.customerDetail.customerMobile &&
        !!this.customerDetail.sell &&
        !!this.customerDetail.customerPostalCode && 
        this.isNotShowErrMsg && 
        ((this.customerDetail.customerMobile.startsWith('02') &&
        this.customerDetail.customerMobile.length >= 9 &&
        this.customerDetail.customerMobile.length <= 11) ||
        (this.customerDetail.customerMobile.startsWith('+642') &&
        this.customerDetail.customerMobile.length >= 10 &&
        this.customerDetail.customerMobile.length <= 12)) &&
        !this.$v.customerDetail.$invalid &&
        this.isValidLocation &&
        this.isCorrectEmailDomain
      );
    },
    sells: function() {
      return ["Now", "Later",  "I'm not selling"];
    },
    mobileMediaQuery: function() { 
      return window.matchMedia('(min-width: 768px)');
    },
    phoneNumberRuleCheck() {
      return this.$v.customerDetail.customerMobile.$dirty && !this.$v.customerDetail.customerMobile.rule;
    },
    postalCodeHelperText() {
      return this.customerDetail.customerPostalCode !== ""
        ? "Invalid location"
        : "Please enter a suburb/postcode";
    },
  },
  validations: {
    customerDetail: {
      customerName: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      customerEmail: {
        required,
        email,
        maxLength: maxLength(256),
      },
      customerMobile: {
        required,
        valid: function() {
          return this.isNotShowErrMsg;
        },
        rule: function() {
          if ((this.customerDetail.customerMobile.startsWith('02') && this.customerDetail.customerMobile.length >= 9 && 
            this.customerDetail.customerMobile.length <= 11) ||
            (this.customerDetail.customerMobile.startsWith('+642') && this.customerDetail.customerMobile.length >= 10 && 
            this.customerDetail.customerMobile.length <= 12)
          ) {
            return regexPhoneRule(this.customerDetail.customerMobile);
          }
          return true;
        },
      },
      sell: {
        required,
      },
      customerPostalCode: {
        required,
        valid: function() {
          const locationDetails = LocationValMap(this.customerDetail.customerPostalCode);
          return locationDetails.state;
        },
      },
    },
  },
  watch: {
    'customerDetail.customerMobile'() {
      this.$v.customerDetail.customerMobile.$touch();
      if ((this.customerDetail.customerMobile.startsWith('02') && this.customerDetail.customerMobile.length < 9) ||
        (this.customerDetail.customerMobile.startsWith('+642') && this.customerDetail.customerMobile.length < 12)
      ) {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = false;
      } else if (!this.phoneNumberValidCheck()) {
        this.isPhoneNumberValid = true;
      } else if (this.phoneNumberRuleCheck) {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = true;
      } else {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = false;
      }
    },
    'customerDetail.customerEmail': {
      handler: debounce(function (newVal) {
        this.checkEmailWithDns(newVal);
      }, 500),
      immediate: false, // The watcher will not run when the component is created
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.nameInput) {
        this.$nextTick(() => this.$refs.nameInput.focus());
      }
    }, 1000);
    
    this.customerDetail = {
      ...this.customerDetail,
      kms: this.customerDetail.kms,
      keys:
        this.customerDetail.key === "2+"
          ? 2
          : parseInt(this.customerDetail.key),
      interiorCondition: this.customerDetail.interior,
      exteriorCondition: this.customerDetail.exterior,
      images: [],
    };
    if (!this.$store.getters.sendPhotoLater) {
      this.customerDetail = {
        ...this.customerDetail,
        images: this.$store.getters.uploadImage,
      };
    }

    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    this.isNotShowErrMsg = true;

    //GoAffPro
    const inputs = document.querySelectorAll("input[placeholder='Enter your name'], input[placeholder='Enter your email'], input[placeholder='Enter your phone']");
    inputs.forEach(input => {
      if (input.placeholder === "Enter your name") {
        input.removeEventListener("change", () => {
          localStorage.setItem("nameValue", input.value);
        });
        input.addEventListener("change", () => {
          localStorage.setItem("nameValue", input.value);
        });
      } else if (input.placeholder === "Enter your email") {
        input.removeEventListener("change", () => {
          localStorage.setItem("emailValue", input.value);
        });
        input.addEventListener("change", () => {
          localStorage.setItem("emailValue", input.value);
        });
      } else if (input.placeholder === "Enter your phone") {
        input.removeEventListener("change", () => {
          localStorage.setItem("phoneValue", input.value);
        });
        input.addEventListener("change", () => {
          localStorage.setItem("phoneValue", input.value);
        });
      }
    });

    window.onpopstate = null;

    if (this.customerDetail.customerPostalCode !== '') this.isValidLocation = true;
  },
  components: {
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
    CustomSelect: () => import("../../components/common/CustomSelect"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    GooglePlaceAutocomplete: () => import("../../components/common/GooglePlaceAutocomplete.vue"),
  },
  created() {
    this.getPriceRange();
  },
};
</script>

<style lang="scss">
.detail-title{
  margin-left: 22px;
}
.customer-detail {
  max-width: 414px;
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 20px;

  .customer-detail-content {
    .title {
      line-height: 20px;
      margin-bottom: 15px;
    }

    .content-detail {
      max-width: 374px;
      padding: 50px 20px;
      background-color: #f1f1f1;
      border-radius: 20px;

      .error, select.form-control.invalid, input.form-control.invalid {
        border-color: red !important;
      }

      .msg-error {
        font-size: 12px;
        color: red;
        position: absolute;
        right: 0;
        text-align: right;
        margin-top: 2px;
      }

      .err-text {
        color: red;
        padding-right: unset !important;
      }

      input {
        font-size: 16px;
        color: #383c3f;
        height: 50px;
        padding-left: 20px;
        font-weight: bold;
        border-color: #b3b3b3;
        border-radius: 10px;

        &:focus {
          box-shadow: none;
          border-color: #00bb65;
          outline: 0;
        }

        &:disabled {
          color: #383c3f;
          background-color: #ffffff;
          border-color: #b3b3b3;
        }
      }

      span {
        &:after {
          background: #f1f1f1;
        }
      }

      p {
        margin-top: 7px;
      }

      .border-lable-flt span {
        background-color: unset;
      }
    }
  }

  .action {
    max-width: 374px;

    .btn-action {
      width: 100%;
      height: 52px;
      padding: 16px 24px;
      margin-top: 12px;
      background-color: #C4C4C4;
      border-radius: 0.5rem;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .active-btn-action {
      background-color: #2BB673;

      &:hover, &:active {
        background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), #2BB673 !important;
      }
    }

    .btn-loading {
      font-size: 16px;
      width: 24px;
      height: 24px;
    }

    .btn-back {
      background-color: #ffffff !important;
      border: 1px solid #2BB673;
      color: #2BB673;
    }

    .btn-submit {
      &:hover, &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #C4C4C4;
        outline: none;
      }
    }
  }
}

.customer-detail-txt-note {
  font-size: 11px;
  color: #989898;
  text-align: center;
  max-width: 414px;
}

.seller-customer {
  color: #000000;
  width: 100%;
  margin: 0 auto;
  text-align: left;

  .seller-checkbox {
    label {
      line-height: 1.5;
    }

    label::before,
    label::after {
      width: 20px;
      height: 20px;
    }

    label::before {
      border-radius: 0;
    }

    a {
      text-decoration: underline;
      color: #000000;
    }
  }

  input:checked ~ label::before {
    color: #ffffff;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
  }

  input:checked ~ label::after {
    background-image: url("~@/assets/images/icons/ic_done_green.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
  }

  span {
    margin: 10px 0 0 10px;
    font-size: 14px;
    font-family: Avenir Next LT W05 Regular;
  }
}

.pickup-location {
  #caret {
    margin: 0 15px !important;
  }
}

.disclaimer {
  text-align: left !important;
  margin-bottom: 25px;
  .disclaimer-text {
    font-family: 'Avenir Next LT W05 Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
  }
}

@media screen and(min-width: 768px) {
  .detail-title{
    margin-left: 70px !important;
  }
  .customer-detail {
    max-width: 1080px;
    padding: 20px 51px 40px 56px;
    .customer-detail-content {
      .title {
        font-size: 20px;
        line-height: 24px;
      }
      .content-detail {
        max-width: 600px;
        padding: 40px;
        .mg-top{
          margin-top: 17px;
        }
      }
    }
    .action {
      flex-direction: row !important;
      max-width: 600px !important;
      justify-content: space-between;
      .btn-action {
        width: 18%;
        outline: none !important;
      }
    }
  }

  .seller-customer {
    width: 500px !important;
  }
}

@media screen and(max-width: 420px) {
  .disclaimer {
    margin-top: -15px !important;
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .content-detail-mobile {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  .content-detail-mobile {
    margin-bottom: 50px !important;
  }
}
</style>
